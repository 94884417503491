<template>
  <section>
    <v-card :dark="$dark.get()" elevation="1">
      <v-card-title class="card_title">
        <section class="header">
          <h1>Модерация товаров</h1>
          <div></div>
        </section>
        <div class="card_filters">
          <p>Фильтры</p>
          <v-checkbox
            v-model="body.missingImage"
            label="Без картинок"
            @change="setAll()"
          />
          <v-checkbox
            v-model="body.missingDescription"
            label="Без описания"
            @change="setAll()"
          />
          <v-select
            v-model="body.status"
            :items="statuses"
            item-text="name"
            item-value="value"
            class="card_select"
            @change="setAll()"
          ></v-select>
          <v-select
            v-model="body.providerId"
            :items="PROVIDERS.allProviders"
            label="Поставщики"
            item-text="name"
            item-value="id"
            class="card_select"
            clearable
            @change="setAll()"
          ></v-select>
        </div>
      </v-card-title>
      <v-card-text>
        <loader v-if="loading"></loader>
        <v-simple-table v-else fixed-header>
          <thead>
            <tr>
              <th>
                <v-checkbox
                  v-model="all"
                  @change="
                    checks.forEach((el) => {
                      el.state = all;
                    })
                  "
                />
              </th>
              <th v-for="item in items.head" :key="item.id">
                <span>{{ item.name }}</span>
              </th>
            </tr>
          </thead>
          <tbody style="max-height: 92vh; min-height: 64vh">
            <tr
              v-for="(item, index) in items.products"
              :key="item.providerProduct.id + '/' + index"
            >
              <td class="cell">
                <v-checkbox
                  v-model="checks[index].state"
                  @change="all = false"
                />
              </td>
              <td class="cell">{{ item.providerProduct.id }}</td>
              <td class="cell">{{ providers[item.providerId] }}</td>
              <td class="cell cell_img">
                <p
                  v-if="
                    item.providerProduct.images
                      ? item.providerProduct.images.length === 0
                      : true
                  "
                >
                  ?
                </p>
                <v-img
                  v-else
                  width="50px"
                  height="50px"
                  :src="item.providerProduct.images[0].fileUrl"
                />
              </td>
              <td class="cell">{{ item.providerProduct.name }}</td>
              <td class="cell">{{ item.providerProduct.description }}</td>
              <td class="cell">
                {{ item.comment ? item.comment : "Не указан" }}
              </td>
              <td class="cell">
                <v-btn
                  icon
                  color="primary"
                  @click="moderate([item], 'APPROVED')"
                >
                  <v-icon>mdi-thumb-up</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  icon
                  @click="
                    showCommentDialog = true;
                    currentProduct = item;
                  "
                >
                  <v-icon>mdi-thumb-down</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="goToPage(item.moderationId)"
                    >
                      <v-icon>mdi-ab-testing</v-icon>
                    </v-btn>
                  </template>
                  <span>Посмотреть изменения</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-pagination
          v-if="totalPages > 1"
          :value="body.page + 1"
          :dark="$dark.get()"
          :total-visible="7"
          :length="totalPages"
          @input="setAll($event - 1)"
        ></v-pagination>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showCommentDialog" activator="parent" width="70%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          Укажите причину отказа в публикации
        </v-card-title>
        <v-card-text>
          <v-form ref="rejectForm">
            <v-textarea
              v-model="comment"
              :rules="[$validate.required]"
              label="Причина отказа"
              auto-grow
              clearable
            ></v-textarea>
          </v-form>
          <v-chip
            v-for="tag in TAGS.moderateTags"
            :key="tag.id"
            class="ma-2"
            @click="comment = tag.name"
          >
            {{ tag.name }}
          </v-chip>
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn color="primary" @click="checkComment()">Подвердить</v-btn>
          <v-btn @click="showCommentDialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PasswordDialog
      :show="showPassDialog"
      @close-modal="closePassDialog($event)"
    />
    <bottom-bar
      :active="active"
      :disapprove="true"
      :disable="states.length > 1"
      @show="(showCommentDialog = true), (massReject = true)"
      @approve="showPassDialog = true"
    >
    </bottom-bar>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import ShowMessage from "@/Functions/message";
import BottomBar from "@/components/BottomBar";
import PasswordDialog from "@/components/Order/PasswordDialog";
export default {
  components: {
    Loader,
    BottomBar,
    PasswordDialog,
  },
  data() {
    return {
      loading: false,
      showCommentDialog: false,
      statuses: [
        {
          name: "Неподтвержденные",
          value: "PENDING",
        },
        {
          name: "Отклоненные",
          value: "REJECTED",
        },
      ],
      body: {
        size: 30,
        page: 0,
        status: "PENDING",
        missingImage: false,
        missingDescription: false,
        providerId: null,
      },
      scroll: null,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 6, name: "Поставщик" },
          { id: 2, name: "Фото" },
          { id: 3, name: "Название" },
          { id: 4, name: "Описание" },
          { id: 5, name: "Комментарий" },
          { id: 9, name: "Действие" },
        ],
        products: [],
      },
      all: false,
      checks: [],
      totalPages: 0,
      comment: "",
      currentProduct: null,
      massReject: false,
      showPassDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      PRODUCTS: "Products/MODERATED",
      PROVIDERS: "Providers/STATE",
      TAGS: "Reviews/STATE",
    }),
    // высчитывает какие продукты выбраны через чекбокс
    states() {
      return this.checks.reduce((accum, x) => {
        if (x.state === true) {
          accum.push(x.id);
        }
        return accum;
      }, []);
    },
    // чтобы показывать кнопку изменения нескольких продуктов
    active() {
      if (this.states.length > 0) return "active";
      return "";
    },
    // объект с поставщиками чтобы получать их имена на основе id
    providers() {
      return this.PROVIDERS.allProviders.reduce((acc, provider) => {
        acc[provider.id] = provider.name;
        return acc;
      }, {});
    },
  },
  watch: {
    showCommentDialog(val) {
      if (!val) {
        this.currentProduct = null;
        this.comment = "";
        this.$refs.rejectForm.resetValidation();
      }
    },
  },
  mounted() {
    this.checkLS();
    this.setAll(this.body.page);
  },
  methods: {
    ...mapActions({
      GET_PRODUCTS_FOR_MODERATION: "Products/GET_PRODUCTS_FOR_MODERATION",
      MODERATE_PRODUCTS: "Products/MODERATE_PRODUCTS",
      GET_ALL_PROVIDERS: "Providers/GET_ALL_PROVIDERS",
      GET_MODERATE_TAGS: "Reviews/GET_MODERATE_TAGS",
    }),
    async setAll(pageNumber = 0) {
      this.loading = true;
      if (!this.PROVIDERS.allProviders.length) {
        await this.GET_ALL_PROVIDERS();
      }
      if (!this.TAGS.moderateTags.length) {
        await this.GET_MODERATE_TAGS();
      }
      this.body.page = pageNumber;
      await this.GET_PRODUCTS_FOR_MODERATION(this.body);
      this.items.products = this.PRODUCTS.content;
      this.totalPages = this.PRODUCTS.totalPages;
      this.loading = false;
      this.setChecks();
      if (this.scroll) {
        setTimeout(() => {
          window.scrollTo(0, this.scroll);
        }, 100);
      }
    },
    async moderate(items, status) {
      this.loading = true;
      const payload = items.map((item) => {
        const obj = {
          comment: this.comment,
          moderationStatus: status,
          productId: item.providerProduct.id,
          providerId: item.providerId,
        };
        return obj;
      });
      const response = await this.MODERATE_PRODUCTS(payload);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage(
          `Статус ${items.length > 1 ? "продуктов" : "продукта"} изменен`,
          true
        );
        this.setAll(this.body.page);
        this.showCommentDialog = false;
        this.massReject = false;
        return;
      }
      this.loading = false;
    },
    closePassDialog(approve) {
      if (approve) {
        this.moderate(this.states, "APPROVED");
      }
      this.showPassDialog = false;
    },
    checkComment() {
      if (this.$refs.rejectForm.validate()) {
        if (this.massReject) {
          this.moderate(this.states, "REJECTED");
        } else {
          this.moderate([this.currentProduct], "REJECTED");
        }
      } else {
        ShowMessage("Укажите причину отказа");
      }
    },

    // новое назначение айдишников для чекбоксов
    setChecks() {
      this.checks = this.items.products.map(function (x) {
        return {
          state: false,
          id: x,
        };
      });
    },
    goToPage(moderationId) {
      const data = {
        body: this.body,
        scroll: window.scrollY,
      };
      sessionStorage.setItem("AyanModerationPage", JSON.stringify(data));
      this.$router.push("/product-difference/" + moderationId);
    },
    checkLS() {
      let ls = sessionStorage.getItem("AyanModerationPage");
      if (ls) {
        const data = JSON.parse(ls);
        this.body = data.body;
        this.scroll = parseFloat(data.scroll);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  &_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      margin-bottom: 0px !important;
    }
  }
  &_select {
    max-width: 200px;
  }
  &_filters {
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }
}
</style>
